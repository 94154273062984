import React, { useState, useEffect } from 'react';
import { getApiRatesDB } from "../../lib/api";
import { LiaSpinnerSolid } from 'react-icons/lia';

function LenderRatesTable({ltv, lendersID}) {
    const [activeRates, setActiveRates] = useState({});
    
    useEffect(() => {
        getData();       
    }, []);
    const getData = async ()=>{      
        let data = {};
        data['lenderId'] = lendersID;  
        data['value'] = 'first-time-buyer'; 
        data['ltv'] = ltv;  
        data['count'] = 1;  
        let fetchResponseData = await getApiRatesDB([data]);
        // console.log(fetchResponseData)
    }
    return (
        <>
            <table className='table table-sm table-bordered text-center lender-page-rates-table' style={{borderColor: '#060f8f', backgroundColor: '#f2ebeb'}}>
                <thead>
                    <tr>
                        <th className='font-weight-bolder'>Term</th>
                        <th className='font-weight-bolder'>Rate</th>
                        <th className='font-weight-bolder'>Fees</th>
                        <th className='font-weight-bolder'>LTV</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>2 years</td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td>{ltv}%</td>
                    </tr>
                    <tr>
                        <td>2 years</td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td>{ltv}%</td>
                    </tr>
                    <tr>
                        <td>5 years</td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td>{ltv}%</td>
                    </tr>
                    <tr>
                        <td>5 years</td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td><LiaSpinnerSolid className="spinner" color="#002f6a" size={20} /></td>
                        <td>{ltv}%</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
export default LenderRatesTable;
